import React from "react";
import {
  Button,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChurchIcon from "@mui/icons-material/Church";
import { Link, useLocation } from "react-router-dom";
import { styles } from "./styles";
import SubMenu from "./SubMenu";
import PersonIcon from "@mui/icons-material/Person";
import ThemeModeSwitch from "./ThemeModeSwitch";
import { useGlobalState, useSetDarkMode } from "../context/globalContext";
import { title } from "process";

const pages = [{ title: "Home", link: "/" }];
const pagesMobile = [
  { title: "Home", link: "/" },
  { title: "Thesis", link: "/thesis" },
  { title: "About Thesis", link: "/about-thesis" },
  { title: "MainCard", link: "https://maincardmma.com" },
];
const Header: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const { state } = useGlobalState();
  const setDarkMode = useSetDarkMode();

  const { pathname } = useLocation();
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="sticky"
      color="transparent"
      sx={styles.appBar}
      style={{ backgroundColor: state.isDarkMode ? "black" : "white" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <React.Fragment>
            {pathname === "/" ? (
              <React.Fragment>
                <PersonIcon
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 1,
                    color: state.isDarkMode ? "primary.main" : "black",
                  }}
                />
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="./"
                  sx={styles.siteTitle}
                  style={{ color: state.isDarkMode ? "#78AEFF" : "black" }}
                >
                  Shant
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ChurchIcon
                  sx={{
                    display: { xs: "none", md: "flex" },
                    mr: 1,
                    color: state.isDarkMode ? "primary.main" : "black",
                  }}
                />
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="./thesis"
                  sx={styles.siteTitle}
                  fontFamily="Arev"
                  style={{ color: state.isDarkMode ? "#78AEFF" : "black" }}
                >
                  AREV
                </Typography>
              </React.Fragment>
            )}
          </React.Fragment>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon
                sx={{ color: state.isDarkMode ? "primary.main" : "black" }}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pagesMobile.map((page, i) => (
                <MenuItem
                  key={page.title}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={page.link}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      color:
                        pathname === page.link[i] ? "primary.main" : "gray",
                    }}
                  >
                    {page.title}
                  </Typography>
                </MenuItem>
              ))}
              <ThemeModeSwitch
                onChange={(e) => setDarkMode(e.target.checked)}
                checked={state.isDarkMode}
                sx={{
                  "& .MuiSwitch-thumb": {
                    backgroundColor: state.isDarkMode ? "#001e3c" : "#fff",
                  },
                }}
              />
            </Menu>
          </Box>
          {pathname === "/" ? (
            <>
              <PersonIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="./"
                sx={styles.headerLogoText}
                style={{ color: state.isDarkMode ? "#78AEFF" : "black" }}
              >
                Shant
              </Typography>
            </>
          ) : (
            <>
              <ChurchIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="./thesis"
                sx={styles.headerLogoText}
                style={{ color: state.isDarkMode ? "#78AEFF" : "black" }}
              >
                AREV
              </Typography>
            </>
          )}
          <Box sx={styles.menuBox}>
            {pages.map((page, i) => (
              <Button
                key={page.title}
                onClick={handleCloseNavMenu}
                to={page.link}
                component={Link}
                sx={{
                  color:
                    pathname === page.link
                      ? state.isDarkMode
                        ? "#78AEFF"
                        : "black"
                      : "gray",
                  fontWeight: pathname === page.link ? "bold" : "normal",
                  fontSize: 18,
                }}
              >
                {page.title}
              </Button>
            ))}
            <SubMenu />
            <Button
              key="MainCard"
              onClick={() => window.open("https://maincardmma.com", "_blank")}
              sx={{
                color: "gray",
                fontSize: 18,
              }}
            >
              MainCard
            </Button>
            <ThemeModeSwitch
              onChange={(e) => setDarkMode(e.target.checked)}
              checked={state.isDarkMode}
              sx={{
                "& .MuiSwitch-thumb": {
                  backgroundColor: state.isDarkMode ? "#001e3c" : "#fff",
                },
              }}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
